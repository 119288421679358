<script setup>
import { reactive, computed } from 'vue';
import { getEnvironment } from '~/functions/utils-functions';
import permissionsFormWrapper from '~/functions/wrappers/permissions-form-wrapper';
import selfServiceFormWrapper from '~/functions/wrappers/self-service-form-wrapper';
import setupSelfServiceMergeLists from '~/functions/setup/setup-self-service-merge-lists';
import selfServiceForm from '~/components/functional/self-service-form.vue';
import { selfServiceSchema } from '~/constants/schemas';
import PmButton from '~/components/ui/pm-button.vue';
import vModal from '~/components/functional/v-modal.vue';
import { onMounted } from 'vue';
import store from '~/store/index';
import { onBeforeUnmount } from 'vue';
import { convertSelfServiceSchemeToDBProfile } from '~/functions/conversion-functions';

const props = defineProps({
  selfServiceFunctions: {
    default: () => {},
    type: Object
  },
  featureFlagHandler: {
    default: () => {},
    type: Object
  },
  selfServiceProfile: {
    type: Object,
    default: selfServiceSchema()
  },
  defaultThemes: {
    default: () => [],
    type: Array
  }
});
const emit = defineEmits(['fetchSelfServiceProfile']);
const profile = reactive(props.selfServiceProfile);
const environment = getEnvironment(process.env.VUE_APP_STRAPI_URL);

const hasPermissions = computed(() => {
  if (profile?.company)
    return props.featureFlagHandler.allowedFeaturePerProfile(profile.company)[
      'permissions'
    ]
      ? 'permissions'
      : 'default';
  return 'default';
});

const {
  sectionHandler,
  linksArrayHandler,
  socialMediaArrayHandler,
  popupsArrayHandler,
  variableRecordHandler,
  permissionsHandler
} = setupSelfServiceMergeLists(profile, reactive);

const permissionsFormHandler = permissionsFormWrapper(
  profile,
  props.selfServiceFunctions,
  false
);

const controls = selfServiceFormWrapper(
  profile,
  props.selfServiceFunctions,
  props.defaultThemes,
  props.featureFlagHandler,
  sectionHandler,
  linksArrayHandler,
  socialMediaArrayHandler,
  popupsArrayHandler,
  permissionsHandler,
  {
    fetchNewProfileInstance: (profileId) => {
      return emit('fetchSelfServiceProfile', profileId);
    },
    onUpdated: (profile) => {
      store.commit('selfServicePreview/setProfile', {
        ...(profile?.fullProfile || {}),
        ...convertSelfServiceSchemeToDBProfile(profile, false)
      });
    }
  }
);

onMounted(() => {
  // setTimeout(() => {
  store.commit('selfServicePreview/hideLoader');
  store.commit('selfServicePreview/setProfile', {
    ...(profile?.fullProfile || {}),
    ...convertSelfServiceSchemeToDBProfile(profile, false)
  });
  // }, 1000);
});
onBeforeUnmount(() => {
  store.commit('selfServicePreview/showLoader');
  store.commit('selfServicePreview/resetProfile');
});
</script>
<template>
  <div class="flex">
    <self-service-form
      :mode="hasPermissions"
      :controls="{ ...controls, ...permissionsFormHandler }"
      :sectionHandler="sectionHandler"
      :linksArrayHandler="linksArrayHandler"
      :socialMediaArrayHandler="socialMediaArrayHandler"
      :popupsArrayHandler="popupsArrayHandler"
      :featureFlagHandler="featureFlagHandler"
      :variableRecordHandler="variableRecordHandler"
      :environment="environment"
    >
      <template #controlbar>
        <div
          class="flex justify-between md:justify-start py-8 px-6 bg-gray-100 border-t flex-wrap md:flex-nowrap md:pl-16"
        >
          <pm-button
            size="medium md:large"
            class="publish-btn-container mr-4 mb-4 md:mb-0 flex-1 md:flex-none md:w-100"
            :compact="true"
            :disabled="
              controls.savingProfile.subscribing ||
              controls.savingProfile.loading ||
              permissionsFormHandler.savingPermissionsLoading.value
            "
            @click="controls.openModal('update-master')"
          >
            {{ controls.saveButtonTitle.value }}
          </pm-button>
          <pm-button
            size="medium md:large"
            class="publish-permissions-btn-container mb-4 flex-1 md:mr-4 md:flex-none md:w-100 md:mb-0"
            :compact="true"
            :disabled="
              controls.savingProfile.subscribing ||
              controls.savingProfile.loading ||
              permissionsFormHandler.savingPermissionsLoading.value
            "
            @click="permissionsFormHandler.applyPermissions"
          >
            {{ permissionsFormHandler.savePermissionsButtonTitle.value }}
          </pm-button>
          <pm-button
            size="medium md:large"
            v-if="controls.isSuperAdmin && controls.profile.isMaster"
            class="switch-permissions w-full md:w-100"
            :compact="true"
            :background="'outline'"
            :type="'secondary'"
            @click="controls.switchPermissionsForMaster()"
          >
            Switch to
            {{
              controls.updatingMasterPermissions.value
                ? 'child permissions'
                : 'master permissions'
            }}
          </pm-button>
        </div>
      </template>
    </self-service-form>
    <!-- update master modal -->
    <v-modal
      :modalName="'update-master'"
      title="Ready to update your template ?"
    >
      <template #content>
        <div class="bg-white flex flex-col">
          <p class="p-12 text-3xl text-center"
            >Please select an option to continue</p
          >
          <div class="mx-36 pb-12 flex flex-col md:flex-row">
            <pm-button @click="controls.updateMaster"
              >Update template</pm-button
            >
            <div class="w-36 h-12 md:h-0" />
            <pm-button
              @click="controls.updateAllLinkedProfiles"
              :background="'outline'"
              >Update template and all published profiles</pm-button
            >
          </div>
        </div>
      </template>
    </v-modal>
  </div>
</template>
<style lang="css" scoped>
@import url('../../self-service.css');
</style>
