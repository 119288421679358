import { chain } from 'lodash';
import { DateTime } from 'luxon';

export default function (currentRecords, reactive = (v) => v) {
  const records = reactive(currentRecords);

  const checkForDefaultValue = (key) => {
    if (!records[key]) records[key] = {};
  };

  const keyHasType = (key, type) =>
    chain(records).get(key).keys().includes(type).value();

  const addRecord = (key, type) => {
    checkForDefaultValue(key);
    records[key][type] = DateTime.now().toMillis();
  };

  return { records, addRecord, keyHasType };
}
