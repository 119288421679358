/* eslint-disable no-constant-condition */

import { beginningOfTime } from '~/functions/date-functions';
import apiWrapper from '~/functions/wrappers/api-wrapper';

import {
  facadeApiCall,
  facadeApiCallWithDefault,
  formatArrayForChart,
  formatArrayForHistogram,
  formatApprovalData
} from '~/functions/data-functions';
// import { fixTitles } from '~/functions/utils-functions';
import {
  filter,
  sumBy,
  includes,
  map,
  omit,
  sum,
  maxBy,
  capitalize,
  chain,
  isEqual,
  startCase,
  minBy,
  concat
} from 'lodash';

import { DateTime } from 'luxon';
import { convertDBProfileToSelfServiceScheme } from '~/functions/conversion-functions';
import { selfServiceSchema } from '../../constants/schemas';
import userState from '~/store/state-managers/user';

const apiStatisticsConfig = [
  { name: 'visitsCount', path: '/reports/query/all_visits?' },
  { name: 'visitsCountByMonth', path: '/reports/query/monthly_visits?' },
  { name: 'interactionsCount', path: '/reports/query/interactions?' },
  {
    name: 'averageInteractions',
    path: '/reports/query/average_interactions?'
  },
  { name: 'topInteractions', path: '/reports/query/top_interactions?' },
  { name: 'leadForms', path: '/reports/lead-forms?' },
  { name: 'profilesViews', path: '/reports/query/profile_views?' },
  { name: 'profileWithCount', path: '/reports/query/profile_with_count?' }
];

const getInteractionQueryFormatter = (action, type, values) => (query) => ({
  ...query,
  action,
  type,
  values
});

const aggregateSum = (data, filterValue) =>
  sum(
    map(
      filterValue
        ? filter(data, (entry) =>
            filterValue instanceof RegExp
              ? new RegExp(filterValue).test(entry.value)
              : includes(entry.value, filterValue)
          )
        : data,
      'data'
    )
  );

const getSuggestedUsersConfig = (initialQuery, isUser) => [
  {
    report: 'suggestedProfiles',
    apiFunction: isUser
      ? facadeApiCall
      : apiWrapper(
          [
            {
              name: 'profilesViews',
              path: '/reports/query/profile_with_count?'
            }
          ],
          sessionStorage.getItem('_token')
        )['profilesViews'],
    formatQuery: (query) => ({ ...initialQuery, ...query })
  }
];

const getExcelDataConfig = () => [
  {
    report: 'suggestedProfiles',
    apiFunction: apiWrapper(
      [{ name: 'profilesViews', path: '/reports/query/profile_with_count?' }],
      sessionStorage.getItem('_token')
    )['profilesViews'],
    formatQuery: (query) => ({
      orderBy: 'data',
      order: 'desc',
      limit: 100000,
      page: 0,
      isTemplate: false,
      ...query
    })
  }
];

const getCompaniesSearchConfig = (initialQuery, isUser) => [
  {
    report: 'suggestedCompanies',
    apiFunction: isUser
      ? facadeApiCall
      : apiWrapper(
          [
            {
              name: 'suggestedCompanies',
              path: '/companies?'
            }
          ],
          sessionStorage.getItem('_token')
        )['suggestedCompanies'],
    formatQuery: (query) => ({
      ...initialQuery,
      ...query,
      publicationState: 'live'
    })
  }
];

const getProfilesConfig = () => {
  const apiFunction = apiWrapper(
    [{ name: 'profilesViews', path: '/reports/query/profile_with_count?' }],
    sessionStorage.getItem('_token')
  )['profilesViews'];

  return [
    {
      report: 'publishedProfiles',
      apiFunction: apiFunction,
      formatQuery: (query) => ({ ...query, isLive: true })
    },
    {
      report: 'unpublishedProfiles',
      apiFunction: apiFunction,
      formatQuery: (query) => ({ ...query, isLive: false })
    },
    {
      report: 'allProfiles',
      apiFunction: apiFunction
    }
  ];
};

const getDataConfig = (currentDates) => {
  const apiFunctions = apiWrapper(
    apiStatisticsConfig,
    sessionStorage.getItem('_token')
  );
  const admin_average_stats = () => [
    {
      report: 'aboveAverageForLifeTimeVisits',
      apiFunction: apiFunctions['averageInteractions'],
      formatQuery: (query) =>
        getInteractionQueryFormatter(
          'page_view',
          'page_view'
        )(omit(query, ['start', 'end']))
    },
    {
      report: 'clickToCallAverage',
      apiFunction: apiFunctions['averageInteractions'],
      formatQuery: getInteractionQueryFormatter('click', 'phone')
    },
    {
      report: 'clickToNavigateAverage',
      apiFunction: apiFunctions['averageInteractions'],
      formatQuery: getInteractionQueryFormatter(
        'click',
        'url',
        'https://goo.gl/maps/'
      )
    },
    {
      report: 'savetoHomeScreenAverage',
      apiFunction: apiFunctions['averageInteractions'],
      formatQuery: getInteractionQueryFormatter('click', 'pwa-install')
    },
    {
      report: 'addToContactsAverage',
      apiFunction: apiFunctions['averageInteractions'],
      formatQuery: getInteractionQueryFormatter('click', 'save-to-contacts')
    },
    {
      report: 'copyLinksAverage',
      apiFunction: apiFunctions['averageInteractions'],
      formatQuery: getInteractionQueryFormatter('click', 'copy-link')
    },
    {
      report: 'shareAverage',
      apiFunction: apiFunctions['averageInteractions'],
      formatQuery: getInteractionQueryFormatter('share')
    }
  ];
  const standardApis = [
    {
      report: 'topProfileInteractions',
      apiFunction: apiFunctions['topInteractions'],
      format: (data) => {
        const labels = {
          'click accept-cookies': 'Permissions',
          'page_view page_view': 'Profile Views',
          'click url': 'Link Clicks',
          'open dropdown': 'Dropdown Clicks'
        };
        const formattedData = chain(data)
          .map((entry) =>
            entry.action == 'share' ? { ...entry, label: 'share' } : entry
          )
          .groupBy('label')
          .mapValues((entry) => sumBy(entry, 'data'))
          .map((entry, key) => ({ data: entry, label: key }))
          .thru(formatArrayForChart)
          .value();
        return {
          ...formattedData,
          labels: formattedData.labels.map((e) => {
            if (labels[e]) return capitalize(labels[e]);
            return capitalize(e);
          })
        };
      }
    },
    {
      report: 'profileVisits',
      apiFunction: apiFunctions['visitsCountByMonth'],
      format: (data) => {
        return formatArrayForHistogram(
          {
            start: maxBy(
              [
                minBy(
                  [currentDates.start, currentDates.end.plus({ months: -12 })],
                  (d) => d.toMillis()
                ),
                beginningOfTime()
              ],
              (d) => d.toMillis()
            ),
            end: currentDates.end
          },
          map(data, (entry) => ({
            ...entry,
            label: DateTime.fromFormat(entry.label, 'MMMM yyyy').toFormat(
              'MMM yy'
            )
          }))
        );
      }
    },
    {
      report: 'lifetimeVisits',
      apiFunction: apiFunctions['visitsCount'],
      formatQuery: (query) => omit(query, ['start', ' end'])
    },

    {
      report: 'countsForMonth',
      format: (data) => {
        return sumBy(data, 'data');
      },
      apiFunction: apiFunctions['visitsCountByMonth']
    },
    {
      report: 'topLinkInteractions',
      apiFunction: apiFunctions['interactionsCount'],
      formatQuery: getInteractionQueryFormatter(
        'click,open,view-media,scroll',
        'url,phone,email,dropdown,modal,socialmedia,image,video,section,testimonial'
      ),
      format: (data) => {
        return chain(data)
          .filter((entry) => entry.value != 'unknown')
          .reduce((acc, cur) => {
            const getTitle = (title, type) => {
              const presets = {
                video: 'Videos',
                testimonial: 'Testimonials'
              };
              const typeTitle = presets[type] || type;
              return capitalize(title ? title : startCase(typeTitle));
            };
            const title = getTitle(cur.title, cur.type);

            if (!acc[title]) {
              acc[title] = {
                ...cur,
                title
              };
              if (acc[title].value == 'lead-form') acc[title].value = '';
            } else {
              if (acc[title].value != cur.value) {
                acc[title].value = '';
              }
              acc[title].data += cur.data;
            }

            return acc;
          }, {})
          .values()
          .sortBy('data')
          .reverse()
          .value();
      }
    },
    {
      report: 'clickToCall',
      format: aggregateSum,
      apiFunction: apiFunctions['interactionsCount'],
      formatQuery: getInteractionQueryFormatter('click', 'phone')
    },
    {
      report: 'clickToNavigate',
      apiFunction: apiFunctions['interactionsCount'],
      format: (data) =>
        aggregateSum(data, /https:\/\/(goo\.gl\/maps|maps\.google\.com)/g) ||
        aggregateSum(data, /https:\/\/maps.app.goo.gl/g),
      formatQuery: getInteractionQueryFormatter('click', 'url')
    },
    {
      report: 'clickToContactEmail',
      apiFunction: apiFunctions['interactionsCount'],
      format: aggregateSum,
      formatQuery: getInteractionQueryFormatter('click', 'email')
    },
    {
      report: 'clickToContactMessage',
      apiFunction: apiFunctions['interactionsCount'],
      format: aggregateSum,
      formatQuery: getInteractionQueryFormatter('share', 'whatsapp,sms')
    },
    {
      report: 'submitLeadForm',
      apiFunction: apiFunctions['interactionsCount'],
      format: aggregateSum,
      formatQuery: getInteractionQueryFormatter('submit', 'lead-form')
    },
    {
      report: 'savetoHomeScreen',
      apiFunction: apiFunctions['interactionsCount'],
      format: aggregateSum,
      formatQuery: getInteractionQueryFormatter('click', 'pwa-install')
    },

    {
      report: 'addToContacts',
      apiFunction: apiFunctions['interactionsCount'],
      format: aggregateSum,
      formatQuery: getInteractionQueryFormatter('click', 'save-to-contacts')
    },

    {
      report: 'copyLinks',
      apiFunction: apiFunctions['interactionsCount'],
      format: aggregateSum,
      formatQuery: getInteractionQueryFormatter('click', 'copy-link')
    },
    {
      report: 'share',
      apiFunction: apiFunctions['interactionsCount'],
      format: aggregateSum,
      formatQuery: getInteractionQueryFormatter('share')
    },
    {
      report: 'leadForms',
      apiFunction: apiFunctions['leadForms'],
      onError: (data) => (data.value = [])
    },
    {
      report: 'topProfiles',
      apiFunction: userState().isUser
        ? facadeApiCallWithDefault({ data: [], total: 0 })
        : apiFunctions['profileWithCount'],
      formatQuery: (query) => ({
        ...query,
        isLive: true,
        limit: 15,
        orderBy: 'data',
        order: 'desc',
        filter: ''
      }),
      default: () => ({ data: [], total: 0 })
    }
  ];
  if (userState().isSuperAdmin) {
    concat(standardApis, admin_average_stats());
  }
  return standardApis;
};

const selfServiceApiConfig = [{ name: 'getProfile', path: '/profiles/' }];
const selfServiceConfig = (apiFunctions) => {
  const defaultVal = selfServiceSchema();
  const fallbackCallback = ({ callback }) => {
    callback(defaultVal);
  };
  return [
    {
      report: 'selfServiceProfile',
      format: (data) =>
        isEqual(data, defaultVal)
          ? defaultVal
          : convertDBProfileToSelfServiceScheme(data),
      default: () => defaultVal,
      apiFunction: (options) =>
        options.query.profileId
          ? apiFunctions['getProfile']({
              ...options,
              query: {
                populate: 'self-service'
              },
              path: options.query.profileId + '?'
            })
          : fallbackCallback(options)
    }
  ];
};

const defaultThemesApiConfig = [
  {
    name: 'getDefaultThemes',
    path: '/profile-stylings/default-themes'
  }
];
const defaultThemesConfig = (apiFunctions) => {
  return [
    {
      report: 'defaultThemes',
      apiFunction: apiFunctions['getDefaultThemes']
    }
  ];
};

const approvalsConfig = () => {
  return [
    {
      report: 'approvals',
      format: (data) => map(data, formatApprovalData),
      apiFunction: ({ query, callback, catchCallback }) => {
        return query.role == 'user'
          ? facadeApiCall({ callback })
          : apiWrapper(
              [{ name: 'approvals', path: '/approvals?' }],
              sessionStorage.getItem('_token')
            )['approvals']({
              query,
              callback,
              catchCallback
            });
      }
    }
  ];
};

export {
  apiStatisticsConfig,
  getSuggestedUsersConfig,
  getProfilesConfig,
  approvalsConfig,
  getDataConfig,
  selfServiceApiConfig,
  defaultThemesApiConfig,
  defaultThemesConfig,
  selfServiceConfig,
  getExcelDataConfig,
  getCompaniesSearchConfig
};
