import { chain } from 'lodash';
import { computed, ref, inject } from 'vue';
/**
 * This wrapper is responsible for reading and updating permissions from the database
 */
export default function (
  profile,
  selfServiceApi,
  useMasterPermissions = false
) {
  const toast = inject('toast');
  const updatingMasterPermissions = ref(useMasterPermissions);
  const savingPermissionsLoading = ref(false);

  const masterPermissions = ref(profile.masterPermissions);
  const childPermissions = ref(profile.childPermissions);
  const masterPermissionId = ref(profile.masterPermissionId);
  const childPermissionId = ref(profile.childPermissionId);
  const profileId = ref(profile.profileId);
  const profileName = ref(profile.profileName);

  const managePermissions = computed({
    get() {
      return updatingMasterPermissions.value
        ? masterPermissions.value
        : childPermissions.value;
    },
    set(newValue) {
      const currentPermissions = updatingMasterPermissions.value
        ? masterPermissions
        : childPermissions;
      currentPermissions.value = newValue;
    }
  });
  const permissionId = computed({
    get() {
      return updatingMasterPermissions.value
        ? masterPermissionId.value
        : childPermissionId.value;
    },
    set(newValue) {
      const currentPermissionId = updatingMasterPermissions.value
        ? masterPermissionId
        : childPermissionId;
      currentPermissionId.value = newValue;
    }
  });
  async function applyPermissions() {
    savingPermissionsLoading.value = true;
    let result = {};
    console.log(permissionId.value);

    if (permissionId.value) {
      result = await selfServiceApi.updatePermissions(
        permissionId.value,
        managePermissions.value
      );
    } else {
      result = await selfServiceApi.createPermissions(
        profileId.value,
        managePermissions.value,
        profileName.value + ' - Permissions',
        updatingMasterPermissions.value
      );
      if (result.success) {
        permissionId.value = result.id;
      }
    }
    if (result.success) {
      toast({
        success: true,
        message: 'Permissions has been updated!'
      });
      savingPermissionsLoading.value = false;

      return;
    }
    toast({
      success: false,
      message: result.message
    });
    savingPermissionsLoading.value = false;

    return;
  }

  const defaultPermission = {
    edit: true,
    show: true,
    optional: true,
    sortable: true
  };

  function handlePermissionUpdate({ type, value, id }) {
    const currentPermissions = updatingMasterPermissions.value
      ? masterPermissions
      : childPermissions;
    currentPermissions.value = chain(currentPermissions.value)
      .find({ key: id })
      .thru((item) => {
        if (item) item[type] = value;
        else
          currentPermissions.value.push({
            ...defaultPermission,
            [type]: value,
            key: id
          });
        return currentPermissions.value;
      })
      .value();
  }

  const resetPermissions = async () => {
    savingPermissionsLoading.value = true;
    try {
      const result = await selfServiceApi.clearPermissions(permissionId.value);
      if (result.success) {
        managePermissions.value = [];
        toast({
          success: true,
          message: 'Permissions Reset!'
        });
      } else {
        toast({
          success: false,
          message: result.message
        });
      }
    } catch (err) {
      toast({
        success: false,
        message: err.message
      });
    } finally {
      savingPermissionsLoading.value = false;
    }
  };

  const savePermissionsButtonTitle = computed(() => {
    if (savingPermissionsLoading.value) {
      return 'Saving...';
    }
    return 'Apply permissions updates';
  });

  const switchPermissionsForMaster = () => {
    updatingMasterPermissions.value = !updatingMasterPermissions.value;
  };

  return {
    applyPermissions,
    managePermissions,
    handlePermissionUpdate,
    resetPermissions,
    updatingMasterPermissions,
    savePermissionsButtonTitle,
    savingPermissionsLoading,
    switchPermissionsForMaster
  };
}
