import { filter, defaultTo, map, chain } from 'lodash';
import { getFieldDataFromItemId } from '~/constants/permissionList';
import { getByKey } from '~/functions/utils-functions';
/**
 * This wrapper is responsible for reading and updating permissions from the database
 */
export default function (
  permissions = [],
  variableRecordHandler = { keyHasType: () => false }
) {
  const itemIsRequired = (itemId) => {
    return !defaultTo(
      permissions.filter((e) => e.key === itemId)?.[0]?.optional,
      true
    );
  };
  const itemIsEditable = (itemId) => {
    return defaultTo(
      permissions.filter((e) => e.key === itemId)?.[0]?.edit,
      true
    );
  };
  const itemIsShown = (itemId) => {
    return defaultTo(
      permissions.filter((e) => e.key === itemId)?.[0]?.show,
      true
    );
  };

  const requiredForPublish = (profile) => {
    const requiredFields = [];
    for (const index in permissions) {
      const item = permissions[index];
      if (!item.optional) {
        const validator = getFieldDataFromItemId(item.key);
        if (validator.validation && validator.name) {
          if (
            !validator.validation(getByKey(profile, validator.name) || false)
          ) {
            requiredFields.push(validator);
          }
        }
      }
    }

    return filter(requiredFields, (e) => e.title);
  };
  return {
    itemIsRequired,
    itemIsEditable,
    itemIsShown,
    requiredForPublish,
    permissions: map(permissions, (permissionEntry) => {
      const canEdit = chain([
        '',
        '.icon',
        '.image',
        '.date',
        '.url',
        '.title',
        '.content'
      ])
        .map(
          (type) =>
            variableRecordHandler.keyHasType(
              permissionEntry.key + type,
              'update'
            ) ||
            variableRecordHandler.keyHasType(permissionEntry.key + type, 'add')
        )
        .some()
        .value();

      permissionEntry.edit = canEdit || permissionEntry.edit;
      return permissionEntry;
    })
  };
}
